import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import Toggle, { ToggleProps } from '@amzn/awsui-components-react/polaris/toggle';
import type { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/internal/events';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface TopNavigationBarProps {
  onToggle: (event: NonCancelableCustomEvent<ToggleProps.ChangeDetail>) => void;
  checked: boolean;
}

const TopNavigationBar = (props: TopNavigationBarProps): JSX.Element => {
  const navigate = useNavigate();
  const handleClick = (event: any) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  return (
    <TopNavigation
      identity={{
        href: '/mainAppAuthorised',
        title: 'Asset Collector',
        logo: {
          src: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgNDMgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGZpbGw9IiMyMzJmM2UiIHN0cm9rZT0iI2Q1ZGJkYiIgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0MiIgaGVpZ2h0PSIzMCIgcng9IjIiPjwvcmVjdD4KICAgICAgICA8dGV4dCBmb250LWZhbWlseT0iQW1hem9uRW1iZXItUmVndWxhciwgQW1hem9uIEVtYmVyIiBmb250LXNpemU9IjEyIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHRzcGFuIHg9IjkiIHk9IjE5Ij5Mb2dvPC90c3Bhbj4KICAgICAgICA8L3RleHQ+CiAgICA8L2c+Cjwvc3ZnPgo=',
          alt: 'Delonix',
        },
      }}
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu',
      }}
      utilities={[
        {
          type: 'button',
          text: 'Home',
          href: '/mainAppAuthorised',
          onClick: handleClick,
        },
      ]}
      search={
        <Toggle
          onChange={props.onToggle}
          checked={props.checked}
        >
          {props.checked ? 'Switch to light mode' : 'Switch to dark mode'}
        </Toggle>
      }
    />
  );
};

export default memo(TopNavigationBar);
