import { useCallback, useState, memo, useMemo } from 'react';
import { ToggleProps } from '@amzn/awsui-components-react/polaris/toggle';
import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import type { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/internal/events';
import TopNavigationBar from '../TopNavigationBar';
import { useLocation, Outlet } from 'react-router-dom';
import { AppContext, AppContextInfo } from '../../contexts/appContext';
import LoginAgainModal from '../LoginAgainModal';

const MainApp = (): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const [showLoginAgainModal, setShowLoginAgainModal] = useState(false);

  const contextValue: AppContextInfo = useMemo(() => ({ setShowLoginAgainModal }), []);

  const setToggleStatus = useCallback((event: NonCancelableCustomEvent<ToggleProps.ChangeDetail>) => {
    setChecked(event.detail.checked);
  }, []);

  const location = useLocation();

  const getContentType = (): AppLayoutProps.ContentType => {
    if (location.pathname.includes('recordAsset') || location.pathname.includes('uploadAssets')) return 'form';
    else return 'table';
  };

  return (
    <div className={checked ? 'awsui-polaris-dark-mode awsui-visual-refresh' : 'awsui-visual-refresh'}>
      <AppContext.Provider value={contextValue}>
        <TopNavigationBar
          onToggle={setToggleStatus}
          checked={checked}
        />
        <LoginAgainModal visible={showLoginAgainModal} />
        <AppLayout
          contentType={getContentType()}
          toolsHide={true}
          navigationHide={true}
          content={
            <>
              <Outlet />
            </>
          }
        />
      </AppContext.Provider>
    </div>
  );
};

MainApp.displayName = 'MainApp';
export default memo(MainApp);
