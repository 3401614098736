import * as procoreIframeHelpers from '@procore/procore-iframe-helpers';
import { PROCORE_OAUTH_CALL } from '../common/constants';
import { useNavigate } from 'react-router-dom';

export interface SuccessPayload {
  code: string;
}

export const ProcoreLandingPage = (): JSX.Element => {
  const context = procoreIframeHelpers.initialize();

  const navigate = useNavigate();

  const handleSignInClick = () => {
    context.authentication.authenticate({
      url: PROCORE_OAUTH_CALL,

      onSuccess: function (payload: SuccessPayload) {
        navigate('/mainAppAuthorize', { state: payload });
      },

      onFailure: function (error: any) {
        // eslint-disable-next-line no-console
        console.log('Error from procore authentication window', error);
      },
    });
  };

  return (
    <div>
      <h1>Welcome to the Asset creator.</h1>
      <button onClick={handleSignInClick}>Sign in with Procore</button>
    </div>
  );
};
